import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import * as Sentry from '@sentry/angular';
import { SentryCategory } from '../models/sentry.model';
import { interval } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class PwaService {
  constructor(
    private swUpdate: SwUpdate,
    private snackbar: MatSnackBar
  ) {
    const everyHour$ = interval(1 * 60 * 60 * 1000);

    everyHour$.subscribe(async () => {
      const updateFound = await swUpdate.checkForUpdate();
      if (updateFound) {
        Sentry.addBreadcrumb({
          category: SentryCategory.serviceWorker,
          message: 'Update Found',
          type: 'info',
          level: 'info'
        });

        const ref = this.snackbar.open(
          'A new version of the app is available. Please refresh or the app will refresh in 30 seconds.',
          'Refresh',
          {
            duration: 30000
          }
        );
        ref.onAction().subscribe(() => {
          document.location.reload();
        });
        setTimeout(() => {
          document.location.reload();
        }, 30000);
      }

      swUpdate.unrecoverable.subscribe(event => {
        Sentry.captureException(event.reason);
        this.snackbar.open(
          'An error has occurred that requires a page reload. The page will be refreshed in 5 seconds.',
          null,
          {
            panelClass: 'error-toast'
          }
        );
        setTimeout(() => {
          document.location.reload();
        }, 5000);
      });
    });

    this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          Sentry.addBreadcrumb({
            category: SentryCategory.serviceWorker,
            message: 'Version detected',
            type: 'info',
            level: 'info',
            data: {
              version: evt
            }
          });
          break;
        case 'VERSION_READY':
          Sentry.addBreadcrumb({
            category: SentryCategory.serviceWorker,
            message: 'Version Ready',
            type: 'info',
            level: 'info',
            data: {
              currentVersion: evt.currentVersion.hash,
              latestVersion: evt.latestVersion.hash
            }
          });
          break;
        case 'VERSION_INSTALLATION_FAILED':
          Sentry.addBreadcrumb({
            category: SentryCategory.serviceWorker,
            message: 'Version Installation Failed',
            type: 'info',
            level: 'info',
            data: {
              version: evt.version.hash
            }
          });
          this.forceReload();
          break;
      }
    });
  }

  private forceReload() {
    this.snackbar.open('A new version of the app is available and requires a page reload. Downloading now...');
    setTimeout(() => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          for (let registration of registrations) {
            registration.unregister();
          }
          window.location.reload();
        });
      }
    }, 5000);
  }
}
