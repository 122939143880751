import { Component } from '@angular/core';

@Component({
    selector: 'loading-spinner',
    template: `
    <img src="../../../assets/images/sympliact@3x.png" alt="loader" class="loading" />
  `,
    styleUrls: ['loading-spinner.component.scss'],
    standalone: false
})
export class LoadingSpinnerComponent {}
